import React, { useState, useEffect } from "react";
import { Drawer, Button, Badge, Menu, Avatar, Popover, List } from "antd";
import styled from "styled-components";
import { MenuOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import logo from "assets/images/logo/badili-logo.png";
import UserService from "services/UserService";
import ModalService from "components/elements/modal/ModalService";
import LogoutConfirmation from "app/auth/LogoutConfirmationModal";
import SearchBar from "./SearchBar";
import { useAuth } from "context/AuthContext";

const StyledNav = styled.nav`
  background-color: white;
  height: 64px;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 600;
  padding: 0 16px;
`;
const StyledLogo = styled.img`
  height: 85px;
  margin-left: 1rem;
`;
const StyledLogoWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const StyledMenu = styled(Button)`
  @media (min-width: 992px) {
    display: none;
  }
`;
const StyledToogleMenu = styled(Button)`
  @media (min-width: 992px) {
    display: show;
  }
  @media (max-width: 993px) {
    display: none;
  }
`;
const StyledRight = styled.div`
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  align-items: center;
  gap: 20px;
`;

const StyledNotificationWrapper = styled(List)`
  width: 20rem;
  height: 100;
  overflow: "auto";
`;
const StyledIcon = styled.a`
  font-size: 1.2rem;
`;
const StyledProfileMenu = styled(Menu)`
  border-right: none !important;
`;
const StyledMenuItem = styled(Menu.Item)`
  height: 25px !important;
  line-height: 25px !important;
  padding: 0 !important;
  &.ant-menu-item:hover {
    color: rgb(88, 202, 185);
  }
`;

const NavBar = ({ menu, name, onToggleClick }) => {
  const {
    authState: { user },
  } = useAuth();
  const [visible, setVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [manageLocation, setManageLocation] = useState("");
  const { pathname } = useLocation();

  const hide = () => {
    setPopoverVisible(false);
  };

  const handlePopoverVisibleChange = (newVisible) => {
    setPopoverVisible(newVisible);
  };

  const content = (
    <div>
      <StyledNotificationWrapper
        itemLayout="horizontal"
        dataSource={[1, 2, 3, 4]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
              description={
                <Link to="" className="text-muted">
                  New customer request from Kelvin Kamau for iphone xs
                </Link>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );

  const openLogoutConfirmationModal = () => {
    hide();
    ModalService.open(LogoutConfirmation);
  };

  const items = [
    {
      key: "1",
      label: <a href>Profile</a>,
      icon: <span className="icofont icofont-user" />,
    },

    // {
    //   key: "2",
    //   label: <a href>Settings</a>,
    //   icon: <span className="icofont icofont-gear" />,
    // },

    {
      key: "2",
      label: (
        <a href onClick={openLogoutConfirmationModal}>
          Log Out
        </a>
      ),
      icon: <span className="icofont icofont-power" />,
    },
  ];

  const notificationMenu = (
    <StyledProfileMenu>
      {items.map((link) => (
        <StyledMenuItem key={link.key} icon={link.icon}>
          {link.label}
        </StyledMenuItem>
      ))}
    </StyledProfileMenu>
  );
  useEffect(() => {
    //console.log(pathname !== '' ? pathname.substring(3, pathname.lastIndexOf('/')):"");
    setManageLocation(
      pathname !== "" ? pathname.substring(3, pathname.lastIndexOf("/")) : ""
    );
  }, []);
  return (
    <StyledNav>
      {manageLocation === "/management" ? (
        <StyledToogleMenu icon={<MenuOutlined />} onClick={onToggleClick} />
      ) : (
        <></>
      )}
      <StyledMenu
        // type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title={name}
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
        width="80%"
      >
        {menu}
      </Drawer>
      <StyledLogoWrapper>
        <a>
          <StyledLogo src={logo} className="logo" alt="logo" />
          {UserService?.getCurrentUserLoginData()?.country?.toLowerCase() ===
          "ke"
            ? "🇰🇪"
            : UserService?.getCurrentUserLoginData()?.country?.toLowerCase() ===
              "tz"
            ? "🇹🇿"
            : UserService?.getCurrentUserLoginData()?.country?.toLowerCase() ===
              "ug"
            ? "🇺🇬"
            : ""}
        </a>
      </StyledLogoWrapper>

      <div style={{ flex: "1 1 0%" }}> {/* <SearchBar /> */}</div>

      <StyledRight>
        {UserService?.getCurrentUserLoginData()?.partnerName && (
          <b className="letter-icon-title">
            {" "}
            {UserService?.getCurrentUserLoginData()?.partnerName?.toUpperCase()}
          </b>
        )}
        {UserService?.getCurrentUserLoginData()?.warehouse && (
          <b className="letter-icon-title">
            {" "}
            {UserService?.getCurrentUserLoginData()?.warehouse?.toUpperCase()}
          </b>
        )}
        <Badge size="small" count={5}>
          <Popover
            placement="bottomRight"
            title="Unread Notifications"
            content={content}
            trigger="click"
          >
            <StyledIcon className="icofont icofont-notification" />
          </Popover>
        </Badge>
        <Popover
          placement="bottomRight"
          title={
            UserService?.getCurrentUserLoginData() &&
            UserService?.getCurrentUserLoginData()?.fname
              ? UserService?.getCurrentUserLoginData()?.fname +
                " " +
                UserService?.getCurrentUserLoginData()?.lname
              : UserService?.getCurrentUserLoginData() &&
                UserService?.getCurrentUserLoginData()?.name
              ? UserService?.getCurrentUserLoginData()?.name
              : ""
          }
          content={notificationMenu}
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={handlePopoverVisibleChange}
        >
          <Avatar
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              cursor: "pointer",
            }}
          >
            {UserService?.getCurrentUserLoginData()
              ?.fname?.toString()
              ?.charAt(0)}
          </Avatar>
        </Popover>
      </StyledRight>
    </StyledNav>
  );
};

export default NavBar;
